import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"

const ThanksDownload = () => {
  return (
    <Layout lang="zh-CN">
      <Seo
        pagetitle="已完成的信息请求"
        pagepath="/thanks-download"
        pagedesc="已完成的信息请求 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
      />
      <section className="thanks">
        <div className="l-container">
          <h3 className="thanks__title">感谢你下载我们的文件!</h3>
          <p className="thanks__subtitle">
            下载文件的链接将被发送到你提供的电子邮件地址。
            <br />
            还提供免费的在线咨询。
            如需咨询，请使用以下表格选择您喜欢的日期和时间。
          </p>
          <div className="thanks__btn">
            <a
              className="btn--free-consulting"
              href="https://hub.park-lot.com/free-consulting"
              target="_blank"
              rel="noreferrer"
            >
              报名参加免费咨询
            </a>
          </div>
          <hr className="thanks__hr" />
          <div className="qa-list__box">
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                在这个价格下，你到底能开展多少次活动？ 是否有任何额外的费用？
              </dt>
              <dd className="qa-list__data">
                如果你注册了一个固定价格的计划，你可以在没有额外费用的情况下开展任意数量的活动。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">开始运动的过程是什么？</dt>
              <dd className="qa-list__data">
                基本流程是：申请--数据准备和支付--活动创建--活动开始。
              </dd>
              <dd className="qa-list__detail">
                <a href="/qa/starting-a-campaign" className="qa-list__link">
                  了解更多
                </a>
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                我是否可以在申请前检查管理屏幕的实际操作和演示屏幕上的参与者的线索等？
              </dt>
              <dd className="qa-list__data">
                是的，这是有可能的。 欲了解更多信息，请联系。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                我是否需要遵循任何取消的程序？
              </dt>
              <dd className="qa-list__data">不是特别需要</dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                可以收集的转发用户数据的最大数量是多少？
              </dt>
              <dd className="qa-list__data">
                每天可以收集超过100,000个案例。
                如果你需要收集更多，请联系你的联系人。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                我在多大程度上可以获得参与活动的Twitter账户的信息？
              </dt>
              <dd className="qa-list__data">
                基本上可以收集到Twitter上公开的资料数据。
                个人账户上被设置为隐私的信息不能被收集。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                是否有一个机制可以联系到参与活动但没有被抽中的用户？
              </dt>
              <dd className="qa-list__data">
                是的，有。
                宣传活动可以设计成将人们引导到LINE、Instagram或他们自己的网站。
                <br />
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                你能协助我获得一个认证徽章吗？
              </dt>
              <dd className="qa-list__data">
                是的，你可以。 欲了解更多信息，请联系。
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThanksDownload
